import React, { useState, useCallback } from "react";
import Form from "react-bootstrap/Form";
import { addDays } from "date-fns";

import DatePicker, { registerLocale } from "react-datepicker";
import el from "date-fns/locale/el";
import en from "date-fns/locale/en-US";

import { PrismicRichText, SliceZone } from "@prismicio/react";
import {
  FloatingLabel,
  Button,
  Spinner,
  Alert,
  Container,
} from "react-bootstrap";
import { graphql } from "gatsby";
import Turnstile from "react-turnstile";
import { PhoneInput } from "react-international-phone";
import PageBanner from "../components/elements/PageBanner";
import Layout from "../components/common/Layout";
import Seo from "../components/meta/Seo";
import components from "../components/slices/index";
import { linkResolver } from "../utilities/prismic-linkResolver";
import { constructActiveDoc } from "../utilities/helpers";

import "react-datepicker/dist/react-datepicker.css";
import "../styles/pages/contact.scss";

registerLocale("el", el);
registerLocale("en", en);

const Contact = ({ data }) => {
  if (!data) return null;
  const { page } = data;
  const mydata = page.data;
  const form = mydata.form_values[0];
  const language = page.lang;
  let validMessage = "";

  const [phone, setPhone] = useState("");
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState();
  const [validated, setValidated] = useState(false);

  const messages = {
    "en-us": {
      success: "Your form has been successfully submitted.",
      custom: "Value must be greater than 10.000€",
      error: "Something went wrong. Please try again later.",
    },
    "el-gr": {
      success: "Η φόρμα σας έχει υποβληθεί με επιτυχία.",
      custom: "Η τιμή πρέπει να είναι μεγαλύτερη από 10.000€",
      error: "Κάτι πήγε στραβά. Παρακαλώ δοκιμάστε ξανά αργότερα.",
    },
  };

  if (language === "en-us") {
    validMessage = "Please fill out this field!";
  } else {
    validMessage = "Παρακαλώ συμπληρώστε αυτό το πεδίο!";
  }

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      const formElements = e.currentTarget.elements;

      let firstEmptyInput = null;

      for (let i = 0; i < formElements.length; i += 1) {
        const element = formElements[i];

        if (element.tagName === "INPUT" && element.checkValidity() === false) {
          setValidated(true);

          if (!firstEmptyInput) {
            firstEmptyInput = element;
          }
        }
      }

      if (firstEmptyInput) {
        firstEmptyInput.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

        return;
      }

      setLoading(true);

      const formData = new FormData(e.target);

      if (window.sessionStorage.getItem("utm_href")) {
        formData.append("Page URL", window.sessionStorage.getItem("utm_href"));
        window.sessionStorage.removeItem("utm_href");
      }

      fetch("/api/forms/submit", {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((res) => {
          const { success } = res;

          if (success) {
            e.target.reset();
            setPhone(""); // Reset phone input state
            setValidated(false);
            setSubmitStatus({
              message: messages[language].success,
              type: "success",
            });
          } else {
            setSubmitStatus({
              message: messages[language].error,
              type: "danger",
            });
          }
        })
        .catch(() => {
          setSubmitStatus({
            message: messages[language].error,
            type: "danger",
          });
        })
        .finally(() => setLoading(false));
    },
    [language]
  );

  // datepicker
  const [startDate, setStartDate] = useState("");

  const isWeekday = (date) => {
    const day = date.getDay(date);
    return day !== 6 && day !== 0;
  };

  const datePickerFunction = () => {
    const newD = new Date();

    let cases = newD.getDay();
    if (cases === 1 || cases === 2) {
      cases = addDays(new Date(), 3);
    } else if (cases >= 3 && cases <= 5) {
      cases = addDays(new Date(), 5);
    } else if (cases === 6 || cases === 0) {
      cases = addDays(new Date(), 4);
    }

    return cases;
  };

  // timepicker
  const options = [
    "9:00",
    "9:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
  ];

  return (
    <div style={{ overflowX: "hidden" }} className={`${page.type}-page`}>
      <Seo page={page} />
      <Layout activeDocMeta={constructActiveDoc(page)}>
        <PageBanner
          title={page.data.title.text}
          bgImage={page.data.banner_image}
          bgImageMobile={page.data.image_mobile}
          icon={page.data.banner_icon}
          page={page}
        />
        <Container fluid className="contact-bg-pattern">
          <div className="wrapper">
            <div className="contact-container">
              <div className="row inner justify-content-center">
                <div className="col-12 col-md-8 col-xl-7 max-container">
                  <h2 className="h5 text-center mb-4 fw-normal">
                    {mydata.info_text}
                  </h2>
                  <Form
                    className="contact-form"
                    onSubmit={onSubmit}
                    name="contact-form"
                    noValidate
                    validated={validated}
                  >
                    <input type="hidden" name="Form" value="Contact Form" />

                    <Form.Group className="mb-2" controlId="formName">
                      <Form.Label visuallyHidden="true">
                        {form.name}*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={`${form.name}*`}
                        name="Name"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {validMessage}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="formPhone">
                      <Form.Label visuallyHidden="true">
                        {form.phone}
                      </Form.Label>
                      <PhoneInput
                        defaultCountry="gr"
                        value={phone}
                        enableSearch
                        onChange={(phone) => setPhone(phone)}
                        inputProps={{
                          type: "tel",
                          name: "Phone",
                          required: true,
                          pattern: ".{11,}",
                        }}
                        // disableDialCodeAndPrefix
                        // showDisabledDialCodeAndPrefix
                        // disableFormatting
                        inputClassName="form-control"
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className={
                          validated && phone.length < 11 ? "d-block" : ""
                        }
                      >
                        {validMessage}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="formEmail">
                      <Form.Label visuallyHidden="true">
                        {form.email}*
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder={`${form.email}*`}
                        name="Email"
                        required
                        onInvalid={(e) => e.target.setCustomValidity("test")}
                        onInput={(e) => e.target.setCustomValidity("")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {validMessage}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formService">
                      <Form.Select type="text" name="Interested_In" required>
                        <option>{form.services_label}</option>
                        {page.data.select_options.map((option, index) => (
                          <option
                            key={index}
                            disabled={
                              option.option === null || !option.option.trim()
                            }
                          >
                            {option.option || ""}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formLocation">
                      <Form.Label visuallyHidden="true">
                        {form.location}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={form.location}
                        name="Property_Location"
                      />
                    </Form.Group>
                    <Form.Group className="mb-2 row" controlId="formLoanAmount">
                      <div className="col-md-6 mb-2 mb-md-0">
                        <Form.Label visuallyHidden="true">
                          {form.loan_amount}*
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder={`${form.loan_amount}*`}
                          name="Loan_Amount"
                          required
                          onWheel={(e) => e.target.blur()}
                          onChange={(e) => {
                            const inputValue = parseFloat(e.target.value);
                            if (inputValue < 10000) {
                              e.target.setCustomValidity(
                                messages[language].custom
                              );
                            } else {
                              e.target.setCustomValidity("");
                            }
                            e.target.reportValidity();
                          }}
                        />

                        <Form.Control.Feedback type="invalid">
                          {validMessage}
                        </Form.Control.Feedback>
                      </div>

                      <div className="col-md-6">
                        <Form.Label visuallyHidden="true">
                          {form.property_value}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={form.property_value}
                          name="Property_Value"
                          onWheel={(e) => e.target.blur()}
                          onChange={(e) => {
                            const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                            const formatValue = new Intl.NumberFormat(
                              "el-GR"
                            ).format(value);
                            e.target.value = formatValue;
                          }}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                          }}
                        />
                      </div>
                    </Form.Group>
                    <FloatingLabel controlId="formTextarea" className="mb-2">
                      <Form.Control
                        as="textarea"
                        placeholder={form.comments}
                        name="Message"
                        style={{ height: "200px", width: "100%" }}
                      />
                    </FloatingLabel>
                    <Form.Group className="mb-2 mt-4 mx-2 mx-md-5 text-center">
                      <PrismicRichText field={form.description.richText} />
                    </Form.Group>
                    <Form.Group className="mb-2 d-flex flex-column flex-md-row justify-content-center">
                      <input type="hidden" value={startDate} name="Date" />

                      <DatePicker
                        locale={language === "el-gr" ? "el" : "en"}
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                        // minDate={addDays(new Date(), 6)}
                        minDate={datePickerFunction()}
                        startDate={startDate}
                        filterDate={isWeekday}
                        inline
                        showDisabledMonthNavigation
                        className="me-3"
                      />

                      <div className="time-picker mt-3 mt-md-0 ms-md-3">
                        {options.map((option, index) => (
                          <div key={index}>
                            <Form.Check
                              type="radio"
                              id={`radio-${index}`}
                              value={option}
                              label={option}
                              name="Time"
                              className="mycheckbox"
                            />
                          </div>
                        ))}
                      </div>
                    </Form.Group>

                    <Form.Group
                      className="my-4 formBasicCheckbox"
                      controlId="formBasicCheckbox"
                    >
                      <Form.Label className="d-flex align-items-center gap-2 position-relative">
                        <Form.Check
                          type="checkbox"
                          name="Terms"
                          value="accepted"
                          required
                          feedback={validMessage}
                          feedbackType="invalid"
                        />
                        <div className="rte slab-400">
                          <PrismicRichText
                            field={form.checkbox_content.richText}
                            linkResolver={linkResolver}
                          />
                        </div>
                      </Form.Label>
                    </Form.Group>

                    <Turnstile
                      sitekey={process.env.GATSBY_TURNSTILE_SITE_KEY}
                      theme="dark"
                      onError={() => setDisable(true)}
                      onExpire={() => setDisable(true)}
                      onVerify={() => setDisable(false)}
                      className="mb-2 my-turnstile"
                    />

                    <Button
                      type="submit"
                      disabled={disable || loading}
                      className="d-block mx-auto book-now-outline"
                      variant="link"
                    >
                      {form.submit_button_label}
                      {loading && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="ms-2"
                        />
                      )}
                    </Button>
                  </Form>
                  {submitStatus && (
                    <Alert
                      variant={submitStatus.type}
                      className="my-3 text-center"
                      onClose={() => setSubmitStatus()}
                      dismissible
                    >
                      {submitStatus.message}
                    </Alert>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>

        <SliceZone components={components} slices={page.data.body} />
      </Layout>
    </div>
  );
};

export const query = graphql`
  query contactQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }

    page: prismicContact(lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        background_image {
          alt
          gatsbyImageData(placeholder: BLURRED)
          url
        }
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        meta_title
        title {
          text
        }
        banner_image {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        image_mobile {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        info_text
        banner_icon {
          alt
          gatsbyImageData(placeholder: BLURRED)
          url
        }
        form_values {
          checkbox_content {
            richText
          }
          comments
          description {
            richText
          }
          email
          loan_amount
          location
          name
          phone
          property_value
          services_label
          services_options
          submit_button_label
        }
        select_options {
          option
        }
        body {
          ... on PrismicContactDataBodyContactInfo {
            id
            items {
              hours
              image {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
              phone
              title1 {
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicContactDataBodyBenefits {
            id
            items {
              boxes_description {
                richText
              }
            }
            primary {
              description {
                richText
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              title1 {
                text
              }
              boxes
            }
            slice_label
            slice_type
          }
          ... on PrismicContactDataBodyVideo {
            id
            slice_label
            slice_type
            primary {
              show_on_desktop
              show_on_mobile
              video_link {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default Contact;
